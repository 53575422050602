body {
  font-family: "Spectral", serif !important;
  font-size: 1.1rem;
  padding: 0;
  margin: 0;
}

*,
:after,
:before {
  box-sizing: border-box;
}

h1,
h2 {
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0;
}

a {
  color: #a9a389;
  text-decoration: underline;
  -webkit-text-decoration-color: rgba(201, 193, 159, 0.5);
          text-decoration-color: rgba(201, 193, 159, 0.5);
}

a:hover {
  color: #a9a389;
  text-decoration: underline;
  -webkit-text-decoration-color: rgba(201, 193, 159, 0.5);
          text-decoration-color: rgba(201, 193, 159, 0.5);
}

